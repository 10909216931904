(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/input-number/assets/javascripts/components/input-number-button.js') >= 0) return;  svs.modules.push('/components/components/input-number/assets/javascripts/components/input-number-button.js');
"use strict";

const {
  Button
} = svs.ui;
const InputNumberButton = _ref => {
  let {
    children,
    step,
    isDisabled,
    isInverted,
    size,
    isTransparent,
    isFocused,
    onClick
  } = _ref;
  return React.createElement(Button, {
    className: "input-number-button",
    "data-step": step,
    disabled: isDisabled,
    focus: isFocused,
    inverted: isInverted,
    onClick: onClick,
    size: size,
    transparent: isTransparent,
    type: "button"
  }, children);
};
svs.components.input_number.InputNumberButton = InputNumberButton;

 })(window);