(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/input-number/assets/javascripts/prescript.js') >= 0) return;  svs.modules.push('/components/components/input-number/assets/javascripts/prescript.js');

(function(svs) {
  svs.components = svs.components || {};
  svs.components.input_number = svs.components.input_number || {};
  svs.components.input_number.constants = {
    KEY_E: 69,
    KEY_DOT: 190,
    KEY_MIN: 189,
    KEY_PLUS: 187,
    CLASS_BUTTONS: 'input-number-button',
    CLASS_INPUT: 'input-number-input',
    CLASS_ERROR: 'input-number-error',
    TOGGLE_ERROR_TIMEOUT: 1000 
  };
})(svs);


 })(window);