(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/input-number/assets/javascripts/components/input-number.js') >= 0) return;  svs.modules.push('/components/components/input-number/assets/javascripts/components/input-number.js');
"use strict";

const {
  useRef
} = React;
const {
  InputNumberButton,
  InputNumberInput,
  InputNumberText
} = svs.components.input_number;
const {
  constants: {
    CLASS_ERROR
  },
  utils: {
    isWithinBounds,
    isNumber
  }
} = svs.components.input_number;
const InputNumber = _ref => {
  let {
    step = 1,
    minValue = -Infinity,
    maxValue = Infinity,
    focus,
    className = '',
    size = 300,
    block,
    inverted = false,
    transparent = true,
    value = 1,
    onValueChange = () => {},
    possibleValues,
    isStepOnly = false,
    suffix,
    prefix,
    formatAmountFunc
  } = _ref;
  if (possibleValues && possibleValues.length > 0) {
    minValue = 0;
    maxValue = possibleValues.length - 1;
    step = 1;
    isStepOnly = true;
  } else {
    possibleValues = null;
  }
  if (prefix || suffix) {
    isStepOnly = true;
  }
  if (value < minValue) {
    console.warn('your input value is lower than minValue');
    value = minValue;
  } else if (value > maxValue) {
    console.warn('your input value is higher than maxValue');
    value = maxValue;
  }
  const flashRef = useRef();
  const resetShouldFlash = () => {
    flashRef.current.removeEventListener('animationend', handleTransitionend);
  };
  const handleTransitionend = () => {
    flashRef.current.classList.remove(CLASS_ERROR);
    resetShouldFlash();
  };
  const setShouldFlash = () => {
    flashRef.current.classList.add(CLASS_ERROR);
    flashRef.current.addEventListener('animationend', handleTransitionend);
  };
  const changeValue = newValue => {
    if (!isNumber(newValue)) {
      newValue = value;
    } else if (newValue > maxValue) {
      newValue = maxValue;
      setShouldFlash();
    } else if (newValue < minValue) {
      newValue = minValue;
      setShouldFlash();
    }
    if (newValue !== value) {
      value = newValue;
      onValueChange(newValue);
    }
  };
  const stepUpSize = step;
  const stepDownSize = -step;
  const onStepChange = stepSize => {
    changeValue(value + stepSize);
  };
  const onStepUp = () => {
    onStepChange(stepUpSize);
  };
  const onStepDown = () => {
    onStepChange(stepDownSize);
  };
  const getInputValue = () => {
    let currentValue = possibleValues ? possibleValues[value] : value;
    if (formatAmountFunc) {
      currentValue = formatAmountFunc(currentValue);
    }
    return currentValue;
  };
  const convertInputValue = currentTarget => {
    let v;
    if (currentTarget.valueAsNumber !== undefined) {
      v = !isNaN(currentTarget.valueAsNumber) ? currentTarget.valueAsNumber : value;
    } else {
      const valueNumber = Number(currentTarget.value);
      v = "".concat(valueNumber) === currentTarget.value ? valueNumber : value;
    }
    return v;
  };
  const onInputChange = _ref2 => {
    let {
      currentTarget
    } = _ref2;
    let inputValue = convertInputValue(currentTarget);
    if (possibleValues) {
      let oldDiff = Infinity;
      const pl = possibleValues.length;
      for (let i = 0; i < pl; i++) {
        const newDiff = Math.abs(possibleValues[i] - inputValue);
        if (newDiff > oldDiff) {
          inputValue = i - 1;
          break;
        } else {
          oldDiff = newDiff;
        }
      }
    }
    changeValue(inputValue);
  };
  const onInputBlur = _ref3 => {
    let {
      currentTarget
    } = _ref3;
    const inputValue = convertInputValue(currentTarget);
    changeValue(inputValue);
  };
  const onInputFocus = _ref4 => {
    let {
      target
    } = _ref4;
    target.select && target.select();
  };
  const onInputKeyDown = event => {
    const key = event.key;
    if (!['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'Backspace', 'Tab'].includes(key)) {
      if (['ArrowUp', 'ArrowRight', 'Up', 'Right'].includes(key)) {
        changeValue(value + step);
      } else if (['ArrowDown', 'ArrowLeft', 'Down', 'Left'].includes(key)) {
        changeValue(value + -1 * step);
      }
      event.preventDefault();
      event.stopPropagation();
    }
  };
  const inputValue = getInputValue();
  const Input = prefix || suffix ? InputNumberText : InputNumberInput;
  return React.createElement("div", {
    className: "input-number btn-group ".concat(className.concat(block ? ' btn-group-block' : '')),
    ref: flashRef
  }, React.createElement(InputNumberButton, {
    isDisabled: value === minValue,
    isFocused: focus,
    isInverted: inverted,
    isTransparent: transparent,
    onClick: onStepDown,
    size: size,
    step: stepDownSize
  }, "-"), React.createElement(Input, {
    block: block,
    inverted: inverted,
    isStepOnly: isStepOnly,
    maxValue: maxValue,
    minValue: minValue,
    onInputBlur: onInputBlur,
    onInputChange: onInputChange,
    onInputFocus: onInputFocus,
    onInputKeyDown: onInputKeyDown,
    prefix: prefix,
    size: size,
    suffix: suffix,
    transparent: transparent,
    value: inputValue
  }), React.createElement(InputNumberButton, {
    isDisabled: value === maxValue,
    isFocused: focus,
    isInverted: inverted,
    isTransparent: transparent,
    onClick: onStepUp,
    size: size,
    step: stepUpSize
  }, "+"));
};
svs.components.input_number.InputNumber = InputNumber;

 })(window);