(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/input-number/assets/javascripts/utils.js') >= 0) return;  svs.modules.push('/components/components/input-number/assets/javascripts/utils.js');
"use strict";


(function (svs) {
  function isWithinBounds(value, props) {
    const {
      minValue = value,
      maxValue = value
    } = props;
    return value >= minValue && value <= maxValue;
  }
  function isNumber(num) {
    return typeof num === 'number' && isFinite(num) && Math.floor(num) === num;
  }
  function getValidValue(currentValue, previousValue, props) {
    const {
      minValue = -Infinity,
      maxValue = Infinity
    } = props;
    if (!isNumber(currentValue)) {
      return previousValue;
    }
    return currentValue < minValue ? minValue : currentValue > maxValue ? maxValue : currentValue;
  }
  svs.components.input_number.utils = {
    isWithinBounds,
    isNumber,
    getValidValue
  };
})(svs);

 })(window);