(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/input-number/assets/javascripts/components/input-number-text.js') >= 0) return;  svs.modules.push('/components/components/input-number/assets/javascripts/components/input-number-text.js');
"use strict";

const InputNumberText = _ref => {
  let {
    prefix,
    suffix,
    size,
    transparent,
    block,
    inverted,
    onInputBlur,
    onInputChange,
    onInputFocus,
    onInputKeyDown,
    value
  } = _ref;
  const classNames = ['btn', "btn-".concat(size)];
  if (block) {
    classNames.push('btn-block');
  }
  if (transparent && inverted) {
    classNames.push('btn-transparent-inverted');
    classNames.push('input-number-input-is-transparent-inverted');
  } else if (transparent) {
    classNames.push('btn-transparent-default');
  } else if (inverted) {
    classNames.push('btn-transparent-default');
  } else {
    classNames.push('btn-transparent-default');
    classNames.push('input-number-input-is-focus');
  }
  prefix = prefix || '';
  suffix = suffix || '';
  const inputValue = "".concat(prefix).concat(value).concat(suffix);
  return React.createElement("input", {
    className: "input-number-input ".concat(classNames.join(' ')),
    inputMode: "numeric",
    onBlur: onInputBlur,
    onChange: onInputChange,
    onFocus: onInputFocus,
    onKeyDown: onInputKeyDown,
    readOnly: true,
    type: "text",
    value: inputValue
  });
};
svs.components.input_number.InputNumberText = InputNumberText;

 })(window);